<template>
  <form class="bg-white rounded-8" @submit.prevent="handleSubmit">
    <div class="d-flex align-items-center justify px-24 py-16 border-bottom">
      <h2 class="fs-20 me-auto mb-0">Pelajaran</h2>
      <button
        type="submit"
        class="btn btn-primary"
        :class="{ 'is-loading': isSubmitting }"
        :disabled="isSubmitting"
      >
        Simpan
      </button>
    </div>

    <div class="p-24">
      <InputField
        label="Pelajaran"
        required
        v-model="input.name"
        :error="errors.name"
      />
    </div>
  </form>
</template>

<script>
import get from 'lodash/get';
import subjectRepository from '../../../api/repositories/subjectRepository';

import InputField from '../../form/InputField.vue';

export default {
  components: { InputField },

  props: ['subject'],

  data() {
    return { errors: {}, input: this.getInitialInput(), isSubmitting: false };
  },

  methods: {
    async handleSubmit() {
      try {
        this.errors = {};
        this.isSubmitting = true;

        if (this.input.id) {
          await subjectRepository.update(this.input);
          this.$alert.success('Pelajaran berhasil diubah');
        } else {
          await subjectRepository.store(this.input);
          this.$alert.success('Pelajaran berhasil dibuat', {
            onOK: () => this.$router.push('/admin/pelajaran'),
          });
        }

        this.$store.commit('subjects/invalidate');
      } catch (error) {
        this.errors = get(error, 'response.data.error.errors', {});
        this.$alert.requestError(error);
      } finally {
        this.isSubmitting = false;
      }
    },

    getInitialInput() {
      const input = this.subject ? this.subject.$toJson() : {};
      return input;
    },
  },

  watch: {
    subject() {
      this.input = this.getInitialInput();
    },
  },
};
</script>
